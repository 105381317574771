import React from "react";
import LatticeGrid from "lattice/lib/components/LatticeGrid";
import { Link } from "gatsby";
import BuffyLogo from "./buffy-logo";

const Header = () => (
  <LatticeGrid fullWidth fullGrid innerGrid className="nav-header" tag="header">
    <Link to="/">
      <BuffyLogo />
    </Link>
  </LatticeGrid>
);

export default Header;
