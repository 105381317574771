import React from "react";
import PropTypes from "prop-types";
import Lattice from "lattice/lib/components/Lattice";
import ContextProvider from "../provider/contextProvider";
import Header from "../components/header";
import Footer from "../components/footer";
import "../styles/layout.scss";

const Layout = ({ children, pageContext }) => {
  if (pageContext.layout === "hp") {
    return (
      <ContextProvider>
        <Lattice>
          <Header />
          {children}
          <Footer />
        </Lattice>
      </ContextProvider>
    );
  }
  return (
    <ContextProvider>
      <Lattice>
        <Header />
        {children}
        <Footer />
      </Lattice>
    </ContextProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.shape({ layout: PropTypes.string }),
};

Layout.defaultProps = {
  pageContext: {},
};

export default Layout;
