import React, { useState, useEffect } from "react";
import Client from "shopify-buy";
import PropTypes from "prop-types";

import Context from "../context/storeContext";

const client = Client.buildClient({
  storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN,
  domain: `${process.env.SHOPIFY_SHOP_NAME}.myshopify.com`,
});

const ContextProvider = ({ children }) => {
  const initialStoreState = {
    client,
    adding: false,
    checkout: { lineItems: [] },
    products: [],
    shop: {},
  };

  const [store, updateStore] = useState(initialStoreState);

  useEffect(() => {
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== "undefined";
      const existingCheckoutID = isBrowser
        ? window.localStorage.getItem("shopify_checkout_id")
        : null;

      const setCheckoutInState = checkout => {
        if (isBrowser) {
          window.localStorage.setItem("shopify_checkout_id", checkout.id);
        }

        updateStore(prevState => ({ ...prevState, checkout }));
      };

      const createNewCheckout = () => store.client.checkout.create();
      const fetchCheckout = id => store.client.checkout.fetch(id);

      if (existingCheckoutID) {
        try {
          const checkout = await fetchCheckout(existingCheckoutID);
          // Make sure this cart hasn’t already been purchased.
          if (!checkout.completedAt) {
            setCheckoutInState(checkout);
            return;
          }
        } catch (e) {
          window.localStorage.setItem("shopify_checkout_id", null);
        }
      }

      const newCheckout = await createNewCheckout();
      setCheckoutInState(newCheckout);
    };

    initializeCheckout();
  }, [store.client.checkout]);

  return (
    <Context.Provider
      value={{
        store,
        /**
         * add variant(s) to cart
         * @param variantIds single id or an array of ids
         * @param quantity quantity to be applied to _all_ ids
         * @returns {*}
         *
         * Note - this method actually updates the quantities
         * for variants in the cart. In other words, repeated calls
         * to 'add' variant X with quantity 1 will result in a cart
         * with quantity 1, not 1 for each time this method was called
         */
        addVariantToCart: (variantIds = [], quantity, customAttributes) => {
          let newVariantIds = variantIds;
          if (!Array.isArray(variantIds)) newVariantIds = [variantIds];
          if (variantIds.length === 0 || !quantity) {
            // console.error('At least one variant id and quantity are required.');
            return;
          }

          updateStore(prevState => ({ ...prevState, adding: true }));

          const checkoutId = store.checkout.id;
          const lineItemsToUpdate = newVariantIds.map(variantId => ({
            variantId,
            quantity: parseInt(quantity, 10),
            customAttributes,
          }));

          store.client.checkout
            .addLineItems(checkoutId, lineItemsToUpdate)
            .then(checkout => {
              updateStore(prevState => ({
                ...prevState,
                checkout,
                adding: false,
              }));
            });
        },
        removeLineItem: (storeClient, checkoutID, lineItemID) =>
          storeClient.checkout
            .removeLineItems(checkoutID, [lineItemID])
            .then(res => {
              updateStore(prevState => ({ ...prevState, checkout: res }));
            }),
        updateLineItem: (
          storeClient,
          checkoutID,
          lineItemID,
          quantity,
          customAttributes,
        ) => {
          const lineItemsToUpdate = [
            {
              id: lineItemID,
              quantity: parseInt(quantity, 10),
              customAttributes,
            },
          ];

          return storeClient.checkout
            .updateLineItems(checkoutID, lineItemsToUpdate)
            .then(res => {
              updateStore(prevState => ({ ...prevState, checkout: res }));
            });
        },
      }}>
      {children}
    </Context.Provider>
  );
};

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContextProvider;
