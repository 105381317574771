import React from "react";

const defaultContextValue = {
  data: {},
  set: () => {},
};

const StoreContext = React.createContext(defaultContextValue);

export default StoreContext;
