import React from "react";
import LatticeGrid from "lattice/lib/components/LatticeGrid";

const Footer = () => (
  <LatticeGrid fullWidth fullGrid innerGrid tag="footer" className="nav-footer">
    <p>
      <a
        href="https://www.instagram.com/buffy/"
        target="_blank"
        rel="noopener noreferrer">
        @Buffy
      </a>{" "}
      &copy;2020
    </p>
  </LatticeGrid>
);

export default Footer;
